/* ==========================================================================
#SETTINGS
========================================================================== */
/* stylelint-disable scss/partial-no-import */
/* ==========================================================================
   #CONFIG
   ========================================================================== */
/*
 * A map of global config settings. Define any project-level configuration,
 * feature switches, etc. in here.
 */
/*
 * You can access data in this map using the following function:
 *
 * config(<key>)
 *
 * Example usage:
 *
 * @if (config(debug) == true) { ...  }
 */
/* ==========================================================================
   #CORE
   ========================================================================== */
/* ==========================================================================
   #GLOBAL
   ========================================================================== */
/* ==========================================================================
   #COLORS
   ========================================================================== */
/* ==========================================================================
   #BREAKPOINTS
   ========================================================================== */
/*
 * You can access data in this map using the following function:
 *
 * breakpoint(<key>)
 *
 * Example usage:
 *
 * @media (min-width: breakpoint(small)) {}
 */
/* ==========================================================================
   #TYPOGRAPY
   ========================================================================== */
/* stylelint-enable scss/partial-no-import */
/* ==========================================================================
   #TOOLS
   ========================================================================== */
/* stylelint-disable scss/partial-no-import */
/* ==========================================================================
   #PIXEL-TO-EM
   ========================================================================== */
/**
 * Mixin to convert pixels to ems
 * https://github.com/sass-mq/sass-mq/blob/master/_mq.scss
 *
 * .usage {
 *   font-size: px2em(16px);
 * }
 */
/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Mixin to drop micro clearfix into a selector. Further reading:
 * http://www.cssmojo.com/the-very-latest-clearfix-reloaded/
 *
 * .usage {
 *   @include clearfix();
 * }
 */
/* ==========================================================================
   #HIDDEN
   ========================================================================== */
/**
 * Mixin to quickly apply accessible hiding to elements
 */
/* ==========================================================================
   #MEDIA-QUERIES
   ========================================================================== */
/* ==========================================================================
   #Z-INDEX
   ========================================================================== */
/* stylelint-disable max-nesting-depth */
/* stylelint-enable max-nesting-depth */
.app-settings__button {
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: block;
}
.app-settings__row {
  position: relative;
  padding-left: 64px;
}
.app-settings__logo {
  position: absolute;
  left: 0;
}
.app-settings__modal {
  height: 100%;
  position: relative;
}
.app-settings__modal__title {
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
}
.app-settings__modal__descritpion {
  font-size: 18px;
  line-height: 28px;
}
.app-settings__modal__buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
}
@media screen and (min-width: 76.8em) {
  .app-settings__modal__buttons {
    position: static;
  }
}

.jg-modal__close {
  z-index: 10;
}

.ReactModal__Content.disconnection-wrapper {
  max-width: 30rem;
  bottom: 0;
  padding: 20px;
  outline: none;
  right: 40px;
  position: absolute;
  border: 1px solid #cccccc;
}
@media screen and (min-width: 76.8em) {
  .ReactModal__Content.disconnection-wrapper {
    bottom: auto !important;
    height: auto;
  }
}

.ReactModal__Content.disconnection-wrapper .jg-modal__content {
  width: 70%;
  min-width: 100%;
}