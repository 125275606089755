.upload-profile-image {
  display: flex;
  flex-direction: column;
}
.upload-profile-image .heading {
  align-self: center;
}
.upload-profile-image .subtitle {
  align-self: center;
}
.upload-profile-image .remove-image-button {
  margin-top: 16px;
}