/* ==========================================================================
#SETTINGS
========================================================================== */
/* stylelint-disable scss/partial-no-import */
/* ==========================================================================
   #CONFIG
   ========================================================================== */
/*
 * A map of global config settings. Define any project-level configuration,
 * feature switches, etc. in here.
 */
/*
 * You can access data in this map using the following function:
 *
 * config(<key>)
 *
 * Example usage:
 *
 * @if (config(debug) == true) { ...  }
 */
/* ==========================================================================
   #CORE
   ========================================================================== */
/* ==========================================================================
   #GLOBAL
   ========================================================================== */
/* ==========================================================================
   #COLORS
   ========================================================================== */
/* ==========================================================================
   #BREAKPOINTS
   ========================================================================== */
/*
 * You can access data in this map using the following function:
 *
 * breakpoint(<key>)
 *
 * Example usage:
 *
 * @media (min-width: breakpoint(small)) {}
 */
/* ==========================================================================
   #TYPOGRAPY
   ========================================================================== */
/* stylelint-enable scss/partial-no-import */
/* ==========================================================================
   #TOOLS
   ========================================================================== */
/* stylelint-disable scss/partial-no-import */
/* ==========================================================================
   #PIXEL-TO-EM
   ========================================================================== */
/**
 * Mixin to convert pixels to ems
 * https://github.com/sass-mq/sass-mq/blob/master/_mq.scss
 *
 * .usage {
 *   font-size: px2em(16px);
 * }
 */
/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Mixin to drop micro clearfix into a selector. Further reading:
 * http://www.cssmojo.com/the-very-latest-clearfix-reloaded/
 *
 * .usage {
 *   @include clearfix();
 * }
 */
/* ==========================================================================
   #HIDDEN
   ========================================================================== */
/**
 * Mixin to quickly apply accessible hiding to elements
 */
/* ==========================================================================
   #MEDIA-QUERIES
   ========================================================================== */
/* ==========================================================================
   #Z-INDEX
   ========================================================================== */
/* stylelint-disable max-nesting-depth */
/* stylelint-enable max-nesting-depth */
.jg-eua-container {
  width: 100%;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 102.4em) {
  .jg-eua-container {
    margin-left: auto;
    margin-right: auto;
  }
}
.jg-eua-errorcontent {
  background-color: #f5f5f5;
}